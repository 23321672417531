import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import { ArrowRightIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import Image from "next/image";
import Link from "next/link";
import { Fragment, useState } from "react";
import {
  BookingWidgetIcon,
  InventoryIcon,
  PaymentsIcon,
  ReportsIcon,
  StaffIcon,
  WaiversIcon,
} from "./partials/icons";

const explore = [
  {
    name: "Inventory Management",
    description: "Manage resources, add dynamic pricing and create promotions & vouchers",
    href: "/explore/inventory-management",
    icon: InventoryIcon,
  },
  {
    name: "Reports",
    description: "Sales, disputes, voucher & gratuity reports",
    href: "/explore/reports",
    icon: ReportsIcon,
  },
  {
    name: "Staff Management",
    description: "Assign & manage user roles & permissions",
    href: "/explore/staff-management",
    icon: StaffIcon,
  },
  {
    name: "Waivers",
    description: "Free integrated customizable digital waivers",
    href: "/explore/waivers",
    icon: WaiversIcon,
  },
];

const sell = [
  {
    name: "Payments",
    description: "Online & offline payments and rates",
    href: "/sell/payments",
    icon: PaymentsIcon,
  },
  {
    name: "Booking Widget",
    description: "Create, select & preview your embedded website widget",
    href: "/sell/booking-widget",
    icon: BookingWidgetIcon,
  },
];

const solutions = [
  {
    name: "Florida Watersports",
    description: "All-in-One: Reservations, Waivers (free), & Florida Boaters Test",
    href: "/florida-watersports",
    icon: BookingWidgetIcon,
  },
  {
    name: "Maine Watersports",
    description: "All-in-One: Reservations, Waivers (free), & Maine Boaters Test",
    href: "/maine-watersports",
    icon: BookingWidgetIcon,
  },
  {
    name: "Canadian Watersports",
    description: "All-in-One: Reservations and Waivers (free), & Rental Boat Safety Checklist",
    href: "/canadian-watersports",
    icon: PaymentsIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [exploreMenuOpen, setExploreMenuOpen] = useState(false);
  const [sellMenuOpen, setSellMenuOpen] = useState(false);
  const [solutionMenuOpen, setSolutionMenuOpen] = useState(false);

  return (
    <header className="relative bg-gradient-to-l from-[#73b3e98a] via-[#6db7ec4d] to-[#e9d9bb99]">
      <nav
        className="flex items-center justify-between px-6 py-4 mx-auto max-w-7xl lg:px-8"
        aria-label="Global"
      >
        <div className="flex">
          <Link href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">SmartRez</span>
            <Image
              className="w-[166px] h-[38px]"
              src="/site/header/logo.png"
              alt=""
              quality={100}
              width={166}
              height={38}
              unoptimized
            />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden pl-20 lg:flex lg:gap-x-6">
          <Popover className="relative">
            <Popover.Button
              onClick={() => setExploreMenuOpen(!exploreMenuOpen)}
              className="inline-flex items-center text-lg font-normal leading-6 active:bg-transparent active:border-none active:outline-none focus:outline-none gap-x-1 text-neutral-900"
            >
              Explore
              <ChevronDownIcon
                className={classNames(
                  exploreMenuOpen ? "rotate-180" : "",
                  "h-5 w-5 flex-none text-neutral-900",
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 flex w-screen px-4 mt-5 -translate-x-[30%] left-1/2 max-w-max">
                <div className="flex-auto w-screen max-w-md overflow-hidden text-sm leading-6 bg-white shadow-lg rounded-3xl ring-1 ring-gray-900/5 lg:max-w-3xl">
                  <div className="grid grid-cols-1 p-4 gap-x-6 gap-y-1 lg:grid-cols-2">
                    {explore.map((item) => (
                      <div key={item.name} className="relative flex p-4 rounded-lg group gap-x-6">
                        <div className="flex items-center justify-center flex-none mt-1 rounded-lg h-11 w-11 group-hover:bg-white">
                          <item.icon
                            className="w-6 h-6 text-gray-600 group-hover:text-indigo-600"
                            aria-hidden="true"
                          />
                        </div>
                        <div>
                          <Link
                            href={item.href}
                            className="font-semibold text-gray-900 hover:text-primary-600"
                          >
                            {item.name}
                            <span className="absolute inset-0" />
                          </Link>
                          <p className="mt-1 text-gray-600">{item.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="px-8 py-6 border-t-2 border-gray-100">
                    <div className="flex items-center justify-end px-12 gap-x-3">
                      <Link
                        href="/contact-us"
                        className="flex items-center space-x-1 rounded-xl bg-transparent py-3.5 px-6 text-sm font-semibold text-primary-600 shadow-sm border border-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                      >
                        <span>Book a Demo</span>
                        <ArrowRightIcon className="w-5 h-5" aria-hidden="true" />
                      </Link>
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button
              onClick={() => setSellMenuOpen(!sellMenuOpen)}
              className="inline-flex items-center text-lg font-normal leading-6 active:bg-transparent active:border-none active:outline-none focus:outline-none gap-x-1 text-neutral-900"
            >
              Sell
              <ChevronDownIcon
                className={classNames(
                  sellMenuOpen ? "rotate-180" : "",
                  "h-5 w-5 flex-none text-neutral-900",
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 flex w-screen px-4 mt-5 -translate-x-1/2 left-1/2 max-w-max">
                <div className="flex-auto w-screen max-w-sm px-3 py-4 overflow-hidden text-sm leading-6 bg-white shadow-lg rounded-3xl ring-1 ring-gray-900/5">
                  {sell.map((item) => (
                    <div
                      key={item.name}
                      className="relative flex items-start p-4 rounded-lg group gap-x-1"
                    >
                      <div className="flex items-start justify-center flex-none mt-1 rounded-lg h-11 w-11 group-hover:bg-white">
                        <item.icon
                          className="w-6 h-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div>
                        <Link
                          href={item.href}
                          className="font-semibold text-gray-900 hover:text-primary-600"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="pr-12 mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Popover className="relative">
            <Popover.Button
              onClick={() => setSolutionMenuOpen(!solutionMenuOpen)}
              className="inline-flex items-center text-lg font-normal leading-6 active:bg-transparent active:border-none active:outline-none focus:outline-none gap-x-1 text-neutral-900"
            >
              Solutions
              <ChevronDownIcon
                className={classNames(
                  solutionMenuOpen ? "rotate-180" : "",
                  "h-5 w-5 flex-none text-neutral-900",
                )}
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 flex w-screen px-4 mt-5 -translate-x-1/2 left-1/2 max-w-max">
                <div className="flex-auto w-screen max-w-sm px-3 py-4 overflow-hidden text-sm leading-6 bg-white shadow-lg rounded-3xl ring-1 ring-gray-900/5">
                  {solutions.map((item) => (
                    <div
                      key={item.name}
                      className="relative flex items-start p-4 rounded-lg group gap-x-1"
                    >
                      <div className="flex items-start justify-center flex-none mt-1 rounded-lg h-11 w-11 group-hover:bg-white">
                        <item.icon
                          className="w-6 h-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div>
                        <Link
                          href={item.href}
                          className="font-semibold text-gray-900 hover:text-primary-600"
                        >
                          {item.name}
                          <span className="absolute inset-0" />
                        </Link>
                        <p className="pr-12 mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
          <Link href="/contact-us" className="text-lg font-normal text-neutral-900">
            Contact Us
          </Link>
        </Popover.Group>
        <div className="hidden space-x-4 lg:flex lg:items-center lg:flex-1 lg:justify-end">
          <Link
            href="/login"
            className="rounded-xl bg-transparent py-3.5 px-6 text-sm font-semibold text-primary-600 shadow-sm border border-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            Log in
          </Link>
          {process.env.NEXT_PUBLIC_HIDE_SIGNUP !== "true" && (
            <Link
              href="/register"
              className="rounded-xl bg-primary-600 py-3.5 px-6 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              Get a Demo
            </Link>
          )}
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full px-6 py-6 overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <Link href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">SmartRez</span>
              <Image
                className=""
                src="/site/header/logo.png"
                alt=""
                width={150}
                height={45}
                quality={100}
                unoptimized
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
          <div className="flow-root mt-6">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="pt-6 space-y-2 divide-y divide-gray-500/10">
                <Disclosure as="div" className="-mx-3 border-t border-gray-500/10">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Explore
                        <ChevronDownIcon
                          className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...explore].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="flex items-start py-2 pl-6 pr-3 space-x-2 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                          >
                            <item.icon
                              className="w-6 h-6 mt-1 text-gray-600 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            <div>
                              <p className="font-semibold text-neutral-1000 text-md">{item.name}</p>
                              <p className="text-sm text-neutral-600">{item.description}</p>
                            </div>
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Sell
                        <ChevronDownIcon
                          className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...sell].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="flex items-start py-2 pl-6 pr-3 space-x-2 text-sm font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                          >
                            <item.icon
                              className="w-6 h-6 mt-1 text-gray-600 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            <div>
                              <p className="font-semibold text-neutral-1000 text-md">{item.name}</p>
                              <p className="text-sm text-neutral-600">{item.description}</p>
                            </div>
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Solutions
                        <ChevronDownIcon
                          className={classNames(open ? "rotate-180" : "", "h-5 w-5 flex-none")}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...solutions].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="flex items-start py-2 pl-6 pr-3 space-x-2 text-sm font-semibold leading-7 text-gray-900 rounded-lg hover:bg-gray-50"
                          >
                            <item.icon
                              className="w-6 h-6 mt-1 text-gray-600 group-hover:text-indigo-600"
                              aria-hidden="true"
                            />
                            <div>
                              <p className="font-semibold text-neutral-1000 text-md">{item.name}</p>
                              <p className="text-sm text-neutral-600">{item.description}</p>
                            </div>
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Link
                  href="/contact-us"
                  className="block px-3 py-2 -mx-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Contact Us
                </Link>
              </div>
              <div className="flex flex-col py-6 space-y-3">
                <Link
                  href="/login"
                  className="text-center rounded-xl text-primary-600 py-3.5 px-6 text-sm font-semibold bg-transparent shadow-sm hover:bg-primary-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-200 border border-primary-600"
                >
                  Log in
                </Link>
                {process.env.NEXT_PUBLIC_HIDE_SIGNUP !== "true" && (
                  <Link
                    href="/register"
                    className="text-center rounded-md bg-transparent py-3.5 px-6 text-sm font-semibold text-primary-600 border border-primary-600 shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  >
                    Get a Demo
                  </Link>
                )}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
