import { GoogleTagManager } from "@next/third-parties/google";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import React, { ReactNode } from "react";

import Footer from "@modules/site/components/Footer";
import Header from "@modules/site/components/Header";
interface SiteLayoutProps {
  children: ReactNode;
}

const SiteLayout = ({ children }: SiteLayoutProps) => {
  return (
    <div id="site" className="font-site">
      <TawkMessengerReact propertyId="66081dd4a0c6737bd1267e6a" widgetId="1hq7r91ce" />
      <GoogleTagManager gtmId="GTM-WHNXD8X9" />
      <Header />
      <div id="" className="w-full mx-auto">
        {children}
      </div>
      <Footer></Footer>
    </div>
  );
};

export default SiteLayout;
