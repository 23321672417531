import React, { FC } from "react";

interface SvgProps {
  width?: number;
  height?: number;
  className?: string;
}

const Inventory: FC<SvgProps> = ({ width = 21, height = 21, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M16.737 5.93262H3.40365C3.18263 5.93262 2.97067 6.02041 2.81439 6.1767C2.65811 6.33298 2.57031 6.54494 2.57031 6.76595V17.5993C2.57031 17.8203 2.65811 18.0323 2.81439 18.1885C2.97067 18.3448 3.18263 18.4326 3.40365 18.4326H16.737C16.958 18.4326 17.17 18.3448 17.3262 18.1885C17.4825 18.0323 17.5703 17.8203 17.5703 17.5993V6.76595C17.5703 6.54494 17.4825 6.33298 17.3262 6.1767C17.17 6.02041 16.958 5.93262 16.737 5.93262Z"
      stroke="#2A5DE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.54932 10.9355H12.5493"
      stroke="#2A5DE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.57031 6.34896L5.48698 3.01562H14.6536L17.5703 6.34896"
      stroke="#2A5DE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Inventory;
